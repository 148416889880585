import { createSlice } from '@reduxjs/toolkit';
import simpleDDP from 'simpleddp'; // ES6
import ws from 'isomorphic-ws';
import _ from 'lodash';




export const articleSlice = createSlice({
    name: 'articles',
    initialState: {
        loading: true,
        countries: [],
        articles: [],
        destinations: [],
        languages: [],
        sections: [],
        usefullinks: []
    },
    reducers: {
        appendDestinations: (state, payload) => {
            state.destinations = [...state.destinations, payload];
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        appendArticles: (state, action) => {
            state.articles = [...state.articles, ...action.payload];
        },
        setArticles: (state, payload) => {
            state.articles = payload.payload;
        },
        setLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setSections: (state, action) => {
            state.sections = action.payload;
        },
        setUsefulLinks: (state, action) => {
            state.usefullinks = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

export const { setArticles, appendArticles, appendDestinations, setCountries, setLanguages, setSections, setUsefulLinks, setLoading } = articleSlice.actions


let server = undefined;
export const getArticlesAsync = (locale, country) => dispatch => {
    dispatch(setLoading(true));
console.log(locale, country, "refetching");
    //if (server === undefined) {
        let opts = {
            endpoint: 'wss://ff-production-eu-west.azurewebsites.net/websocket',
            SocketConstructor: ws,
            reconnectInterval: 5000,
        };
        server = new simpleDDP(opts);
        server.on("connected", function() {
            fetchCountries(server, dispatch, locale).then((countries) => {
                fetchDestinations(server, countries, dispatch, locale, country).then((destinations) => {
                    Promise.all([
                        fetchArticles(server, destinations, dispatch, locale, country),
                        fetchLanguages(server, dispatch),
                        fetchUsefulLinks(server, dispatch, locale, destinations)
                    ]).then(() => {
                        console.log("done refetching")
                        dispatch(setLoading(false));
                    })
                });
            });
        });
    /*} else {
        fetchCountries(server, dispatch, locale).then((countries) => {
            fetchDestinations(server, countries, dispatch, locale, country).then((destinations) => {
                Promise.all([
                    fetchArticles(server, destinations, dispatch, locale, country),
                    fetchLanguages(server, dispatch),
                    fetchUsefulLinks(server, dispatch, locale, destinations)
                ]).then(() => {
                    dispatch(setLoading(false));
                })
            });
        });
    }*/
};


async function fetchCountries(server, dispatch, locale) {
    var countries = server.subscribe("countries", { languageAbbr: locale });
    await countries.restart();
    const data = server.collection("countries").filter(l => l.languageAbbr === locale).fetch();
    dispatch(setCountries(data));

    return data;
}

async function fetchDestinations(server, countries, dispatch, locale, country) {
    await server.subscribe("destinations", { languageAbbr: locale,  countryIdentifier: country }).ready();

    const data = server.collection("destinations").fetch();
    dispatch(appendDestinations(data));
    return data;
}

async function fetchArticles(server, destinations, dispatch, locale) {
    for (let i = 0; i < destinations.length; i++) {
        const groupId = destinations[i].groupId;

        await server.subscribe("articles", {languageAbbr: locale, groupId: groupId }).ready();
    }

    const data = server.collection("articles").fetch();
    const sections = _.groupBy(data, article => article.section);

    dispatch(setSections(sections));
    dispatch(setArticles(data));
}

async function fetchLanguages(server, dispatch) {
    await server.subscribe("languages").ready();
    const data = server.collection("languages").fetch();

    dispatch(setLanguages(data));
}

async function fetchUsefulLinks(server, dispatch, locale, destinations) {
    for (let i = 0; i < destinations.length; i++) {
        const groupId = destinations[i].groupId;

        await server.subscribe("usefullinks", {languageAbbr: locale, groupId: groupId }).ready();
    }

    const data = server.collection("usefullinks").fetch();

    dispatch(setUsefulLinks(data));
}

export const getLanguages = state => state.articles.languages;
export const getSections = state => state.articles.sections;
export const getArticles = state => state.articles.articles;
export const getCountries = state => state.articles.countries;
export const getUsefulLinks = state => state.articles.usefullinks;
export const getAbout = state => {
    const about = state.articles.articles.find(s => s.section === "about");
    if (about) {
        return about;
    }
    else return {
        "id": "Z8Ab6zApb8Pqa5M4Q",
        "languageAbbr": "en",
        "section": "about",
        "title": "About Finn Fram",
        "body": "<div><h1>Finn Fram helps you navigate the Norwegian society and find information about Norwegian rules,  your rights and where you can get help. </h1><p>This includes several links to websites, manuals and relevant information which is important for all migrants and refugees, both those arriving to Norway, applying for asylum or are settled.  <br /></p></div><div><p>  </p></div><div><p>The app was first developed by the Netherlands Red Cross. Hordaland Red Cross is very grateful for the opportunity to use their platform to establish a Norwegian version of the app.  </p></div><div><h1>Disclaimer about the information in Finn Fram </h1></div><div><p>You probably have a number of questions and Finn Fram will try to answer your questions as thoroughly as possible and with up-to-date information. Although we do our best to keep the information we share updated and correct, we cannot guarantee that it is always the most recent. </p></div><div><p>  </p></div><div><p>You will find links to websites that are not managed by the Red Cross in Finn Fram. We have no control over the form, content or availability of these pages. </p></div><div><p>The fact that a link is included does not necessarily mean that it is considered a recommendation or that we support its content. </p></div><div><p>  </p></div><div><p>The Red Cross is not involved in the asylum seeking procedure. </p></div>",
        "groupId": "FL3Kbrs7aabrJ7LZ9",
        "topicIdentifier": "2ZXBPcGcgeAm2FsYt",
        "order": 0,
        "createdAt": "2022-10-12T14:26:30.271Z",
        "createdBy": "7yXF8WT9LszXKgasR",
        "updatedAt": "2023-03-01T15:02:00.841Z"
    };
}
export const getContact = state => {
    const contact = state.articles.articles.find(s => s.section === "contact");
    if (contact) {
        return contact;
    }
    return {
        "id": "AsuM6rFrEwBDQFbLB",
        "languageAbbr": "en",
        "section": "contact",
        "title": "Contact us",
        "body": "<br /><div><p>It is important to point out that the Red Cross\ndoes not take part in asylum processes. The Red Cross cannot help you or give\nyou advice about your application or asylum process.</p>\n\n<p> </p>\n\n<p>If you have questions, suggestions or ideas about\nthe content of this app, you can contact us at Hordaland Red Cross:</p>\n\n<p>Telephone: 05003 (+47 22 05 40 00)</p>\n\n<p>Email: <a target=\"_blank\">dk.hordaland@redcross.no</a> <br /></p></div>",
        "groupId": "FL3Kbrs7aabrJ7LZ9",
        "topicIdentifier": "3Ednhxp7z8wGREfNZ",
        "order": 0,
        "createdAt": "2022-10-13T12:51:43.212Z",
        "createdBy": "DJyNDB4Eq6yR6GvSo",
        "updatedAt": "2022-10-14T11:19:14.969Z"
    };
}
export default articleSlice.reducer;
