const strings = {
    translation: {
        APP_NAME: 'Finn Fram',
        USEFUL_LINKS: 'Useful links',

        SELECT_YOUR_COUNTRY: 'Select your destination',
        CHANGE_COUNTRY_TITLE: 'Change country destination',
        CHANGE_COUNTRY_DESCRIPTION: 'By changing the country destination the app data will be reset. Would you like to continue?',
        CONTINUE: 'Continue',
        CANCEL: 'Cancel',
        DASHBOARD: 'Dashboard',
        ASYLUM_PROCEDURE: 'Asylum procedure',
        HOUSING_AND_SOCIAL: 'Work and welfare',
        EDUCATION_AND_WORK: 'Education and school',
        HEALTH: 'Health',
        FAMILY_IMMIGRATION: 'Family reunification',
        PSYCHOSOCIAL_SUPPORT: 'Psychosocial support',
        PROCEDURE_STEP: 'My rights',
        LOCATIONS: 'Locations',
        NEWS: 'News',
        POINT_IT: 'Point it',
        TRANSPORT: 'Transport',
        FIRST_AID: 'First aid',
        TRANSLATE: 'Interpretation services',
        ABOUT: 'About',
        TRACING: 'Tracing',
        CONTACT: 'Contact',
        FAQ: 'Report',
        DISCLAIMER: 'Disclaimer',
        MISC: 'Corona',
        DOWNLOAD_APP: 'Download the app!',
        DOWNLOAD_APP_DESCRIPTION: 'Our app is available on both Apple and Android phones. Download now to get started!',
        INTRO_TEXT: 'Finn Fram helps you navigate the Norwegian society and find information about Norwegian rules, your rights and where you can get help.',
        FOOTER_ABOUT: 'About Finn fram',
        FOOTER_CONTACT: '',
        FOOTER_DESCRIPTION: '',
        FEATURES_TITLE: 'Discover the information within the app',
        FEATURES: 'Download the app to<ul><li>get information about the asylum seeker procedure</li><li>learn about the rules and regulations in your country</li><li>stay informed about the latest changes</li><li>and much more</li></ul>',
        BANNER: 'en.mp4',
        NORWEGIAN: 'Norsk (Norwegian)',
        UKRAINA: 'українська (Ukrainsk)',
        ENGLISH: 'Engelsk (English)',
        LANGUAGE: 'Language',
        CATEGORY: 'Category',
        DISCRIMINATION: 'Discrimination',
        RACISM: 'Racism',
        SOCIAL_GROUPS: 'Social groups',
        SPECIAL_SITUATIONS: 'Special situations',
        HATE_SPEECH: 'Hate speech',
    }
};

export default strings;
