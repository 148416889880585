import '../App.css';
import React, { useState, useEffect } from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {getAbout, getContact} from "../features/articles/articlesSlice";
import {useSelector} from "react-redux";
import {Loading} from "./Loading";
import {UsefulLink} from "./UsefulLink";
import LanguageDetect from "languagedetect";

export const About = () => {
    const { t } = useTranslation();
    let about = useSelector(getAbout);
    let contact = useSelector(getContact);

    const usefullinks = useSelector(state => state.articles.usefullinks);
    const isLoading = useSelector(state => state.articles.loading);

    if (isLoading) {
        return <Loading></Loading>;
    }

    const getUsefulLinks = article => {
        if (article.usefullinkIds === undefined) {
            return;
        }

        return article.usefullinkIds.map(id => {
            const link = usefullinks.find(l => l.id === id);

            if (link === undefined) {
                return;
            }

            return <div className={'usefullink'}><UsefulLink link={link} /></div>
        });
    }
    return (
        <>
         <Container>
             {about !== undefined && (<Row>
                    <Col>
                        <Container className="article">
                            <h1 className={`header display-4`}>{about.title}</h1>
                            {about.image && <Image src={about.image} />}
                            <div className="filter" dangerouslySetInnerHTML={{__html: about.body}} />
                            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>{getUsefulLinks(about)}</div>
                        </Container>
                    </Col>
                </Row>)}
             {contact !== undefined && (<Row>
                 <Col>
                     <Container className="article">
                         <h1 className={`header display-4`}>{contact.title}</h1>
                         {contact.image && <Image src={contact.image} />}
                         <div className="filter" dangerouslySetInnerHTML={{__html: contact.body}} />
                         <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>{getUsefulLinks(contact)}</div>
                     </Container>
                 </Col>
             </Row>)}
            </Container>
        </>
    );
};
