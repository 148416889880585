const strings = {
  translation: {
    APP_NAME: 'Finn Fram',
    USEFUL_LINKS: 'ጠቓሚ ሊንክታት',

    SELECT_YOUR_COUNTRY: 'Select your destination',
    CHANGE_COUNTRY_TITLE: 'Change country destination',
    CHANGE_COUNTRY_DESCRIPTION: 'By changing the country destination the app data will be reset. Would you like to continue?',
    CONTINUE: 'ቀጽል',
    CANCEL: 'ደምስስ',
    DASHBOARD: 'ሰሌዳ ዳሽቦርድ',
    ASYLUM_PROCEDURE: 'ዑቕባ',
    HOUSING_AND_SOCIAL: 'ስራሕን ድሕነትን',
    EDUCATION_AND_WORK: 'ትምህርትን ቤት ትምህርትን',
    HEALTH: ' ጥዕና',
    FAMILY_IMMIGRATION: 'ምጥርናፍ ስድራቤት',
    PSYCHOSOCIAL_SUPPORT: 'ስነ-ኣእምሮኣዊ ማሕበራዊ ደገፍ',
    PROCEDURE_STEP: 'መሰላተይ',
    LOCATIONS: 'Locations',
    NEWS: 'ዜና',
    POINT_IT: 'Point it',
    TRANSPORT: 'መጓዓዝያ',
    FIRST_AID: 'ቀዳማይ ረድኤት',
    TRANSLATE: 'ኣገልግሎት ትርጕም',
    ABOUT: 'ብዛዕባ',
    TRACING: 'ኣገልግሎት ምድላይ',
    CONTACT: 'መወከሲ',
    FAQ: 'ጸብጻብ ምሃብ',
    DISCLAIMER: 'Disclaimer',
    MISC: 'ኮሮና ቫይረስ',
    DOWNLOAD_APP: 'ነቲ ኣፕሊኬሽን ኣውርዶ!',
    DOWNLOAD_APP_DESCRIPTION: 'ናህና ኣፕሊኬሽንና ኣብ ተሌፎናት ኣፕልን ኣንድሮይድን ይርከብ እዩ ። ሕጂ ምእንቲ ኽትጅምር ኣውርድ!',
    INTRO_TEXT: 'ፊን ፍራም ንማሕበረሰብ ኖርወይ ንኽትድህስሶን ብተወሳኺ ድማ ብዛዕባ ሕግታት ኖርወይ መሰላትካን ከምኡውን ሓገዝ ኣበይ ክትረክብ ከም እትኽእል ሓበሬታ ንኽትረክብን ይሕግዘካ።',
    FOOTER_ABOUT: 'ብዛዕባ ሕርሻ ፊን',
    FOOTER_CONTACT: '',
    FOOTER_DESCRIPTION: '',
    FEATURES_TITLE: 'Discover the information within the app',
    FEATURES: 'Download the app to<ul><li>get information about the asylum seeker procedure</li><li>learn about the rules and regulations in your country</li><li>stay informed about the latest changes</li><li>and much more</li></ul>',
    BANNER: 'en.mp4',
    NORWEGIAN: 'Norsk (Norwegian)',
    UKRAINA: 'українська (Ukrainsk)',
    ENGLISH: 'Engelsk (English)',
    LANGUAGE: 'ቋንቋ',
    CATEGORY: 'ፍረ ነገር',
    DISCRIMINATION: 'ኣድልዎ',
    RACISM: 'ዓሌትነት',
    SOCIAL_GROUPS: 'ማሕበራዊ ጉጅለታት',
    SPECIAL_SITUATIONS: 'ፍሉያት ኩነታት',
    HATE_SPEECH: 'ናይ ጽልኢ ዘረባ',

  }
};

export default strings;
