const strings = {
  translation: {
    APP_NAME: 'Finn Fram',
    USEFUL_LINKS: 'Nyttige linker',
    SELECT_YOUR_COUNTRY: 'Select your destination',
    CHANGE_COUNTRY_TITLE: 'Change country destination',
    CHANGE_COUNTRY_DESCRIPTION: 'By changing the country destination the app data will be reset. Would you like to continue?',
    CONTINUE: 'Fortsett',
    CANCEL: 'Avbryt',
    DASHBOARD: 'Dashbord',
    ASYLUM_PROCEDURE: 'Å søke asyl',
    HOUSING_AND_SOCIAL: 'Arbeid og Velferd',
    EDUCATION_AND_WORK: 'Utdanning og skole',
    HEALTH: 'Helse',
    FAMILY_IMMIGRATION: 'Familiegjenforening',
    PSYCHOSOCIAL_SUPPORT: 'Psykososial førstehjelp',
    PROCEDURE_STEP: 'Mine rettigheter',
    LOCATIONS: 'Locations',
    NEWS: 'Nyheter',
    POINT_IT: 'Point it',
    TRANSPORT: 'Transport',
    FIRST_AID: 'Førstehjelp',
    TRANSLATE: 'Om tolketjenesten',
    ABOUT: 'Om',
    TRACING: 'Oppsporing',
    CONTACT: 'Kontak oss',
    FAQ: 'Rapporter',
    DISCLAIMER: 'Disclaimer',
    MISC: 'Korona',
    DOWNLOAD_APP: 'Last ned appen!',
    DOWNLOAD_APP_DESCRIPTION: 'Appen er tilgjengelig på både Apple og Android telefoner. Last ned nå for å komme i gang!',
    INTRO_TEXT: 'Finn Fram hjelper deg å navigere i det norske samfunnet og finne frem til informasjon om norske regler, dine rettigheter og hvor du kan få hjelp.',
    FOOTER_ABOUT: 'Om Finn Fram',
    FOOTER_CONTACT: '',
    FOOTER_DESCRIPTION: '',
    FEATURES_TITLE: 'Discover the information within the app',
    FEATURES: 'Download the app to<ul><li>get information about the asylum seeker procedure</li><li>learn about the rules and regulations in your country</li><li>stay informed about the latest changes</li><li>and much more</li></ul>',
    BANNER: 'en.mp4',
    NORWEGIAN: 'Norsk (Norwegian)',
    UKRAINA: 'українська (Ukrainsk)',
    ENGLISH: 'English (Engelsk)',
    LANGUAGE: 'Språk',
    CATEGORY: 'Tema',
    DISCRIMINATION: 'Diskriminering',
    RACISM: 'Rasisme',
    SOCIAL_GROUPS: 'Sosiale grupper',
    SPECIAL_SITUATIONS: 'Spesielle situasjoner',
    HATE_SPEECH: 'Hatefulle ytringer',
  }
};

export default strings;
