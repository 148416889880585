import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage, getLanguage } from './languageSlice';
import { getLanguages } from '../articles/articlesSlice';
// Change to Dropdown.css ^
import { Dropdown, Image, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';


export function LanguageDropdownItem() {
    const history = useHistory();
    const languages = useSelector(getLanguages);
    const selectedLanguage = useSelector(getLanguage);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const isSelectedLanguage = abbr => selectedLanguage === abbr;
    const onChangeLanguage = abbr => {
        dispatch(setLanguage(abbr));
    };
    const getLanguageName = abbr => {
        const l = languages.find(l => l.abbr === abbr);
        if (l !== undefined) {
            return l.title;
        }
        return 'English';
    };

    return (
        <>
            {languages.map((l, i) => {
                const classNames = isSelectedLanguage(l.abbr) ? 'dd-links active' : 'dd-links';
                return (
                    <NavDropdown.Item className={classNames} onClick={() => onChangeLanguage(l.abbr)}>
                        {l.title}
                    </NavDropdown.Item>
                );
            })}
        </>
    );
};
