import React from 'react';

import './Download.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const Download = () => {
    const { t } = useTranslation();

    return (
        <Container className="bg-pink pl-0 pr-0 lessheight">
            <Row>
                <Col xs={12} md={12} lg={12} className="align-self-center">
                    <Container className="badges text-center" xs={12} md={12} lg={6} sm={12}>
                        <Row className="justify-content-md-center justify-content-center">
                            <Col>
                                <a>
                                    <h1 className="downloadtext"> {t('DOWNLOAD_APP')} </h1>
                                </a>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={12} sm={12}>
                                <a href='https://apps.apple.com/us/app/refugee-buddy/id1331976412?ls=1'>
                                    <img className="apple-badge" alt='Get it on App Store' src='/assets/badges/appstore-badge.svg' />
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=com.afrogleap.refugeebuddy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                    <img className="google-badge" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>


    )
}
