import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getCountries
} from '../articles/articlesSlice';
import { setCountry } from './countrySlice';
import { Image, Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import './CountryBubble.css';
import PHimage from '../../logo.svg';
import {locations} from "../../config/locations";
import urlSlug from "url-slug";

export function CountryBubble() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const countries = useSelector(getCountries);
    const dispatch = useDispatch();
    const changeSelectedCountry = c => {
        dispatch(setCountry(c));
        //history.push("/sections");
    };

    const getCountryLine = () => {
        return (
            <>
                {countries.map((c) => {
                    const image = locations.find(l => l.locationId.toLowerCase() === c.locationId.toLowerCase());

                    let className = "bubble-img";
                    if (c.locationId.toLowerCase() === "ukrainere i norge") {
                        className += " ukr";
                    }

                    return (
                        <Col className="bubblesections" md={4} xs={4} lg={2} key={c.locationId}>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip>
                                        {t(c.title)}
                                    </Tooltip>
                                }>
                                <div>
                                    <div className="bubble mx-auto">
                                        <Link to={`/sections/${urlSlug(c.locationId)}`} onClick={() => changeSelectedCountry(c)}>
                                            <Image className={className} alt={t(c.title)} src={image.image}/>
                                        </Link>
                                    </div>
                                    <div className="text-center mt-2">
                                        <Link to={`/sections/${urlSlug(c.locationId)}`} onClick={() => changeSelectedCountry(c)} className="section-text">{t(c.title)}</Link>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Col>
                    )
                })}
            </>
        );
    }



    return (
        <Container className="bubble-section-container">
          <div className="bubble-section-container-row">{getCountryLine()}</div>
        </Container>
      )
};
