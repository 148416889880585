const strings = {
  translation: {
  APP_NAME: 'Refugee Buddy',
    USEFUL_LINKS: 'Liens utiles',
    SELECT_YOUR_COUNTRY: 'Sélectionnez votre destination',
    CHANGE_COUNTRY_TITLE: 'Changer de pays de destination',
    CHANGE_COUNTRY_DESCRIPTION: 'Si vous changez de pays de destination, les données de l’app seront réinitialisées. Voulez-vous continuer?',
    CONTINUE: 'Continuer',
    CANCEL: 'Annuler',
    DASHBOARD: 'Dashboard',
    USEFUL_LINKS: 'Liens utiles',
  
      ASYLUM_PROCEDURE: 'Procédure d’asile',
      HOUSING_AND_SOCIAL: 'Logement et social',
      EDUCATION_AND_WORK: 'Éducation et travail',
      HEALTH: 'Santé',
      FAMILY_IMMIGRATION: 'Immigration familiale',
      PSYCHOSOCIAL_SUPPORT: 'Soutien psychosocial',
      PROCEDURE_STEP: 'Et aprés ?',
      LOCATIONS: 'Emplacements',
      NEWS: 'Actualités',
      POINT_IT: 'Pointez-le',
      TRANSPORT: 'Transport',
      FIRST_AID: 'Premiers Secours',
      TRANSLATE: 'Traduire',
      ABOUT: 'A propos',
      TRACING: 'Traçage',
      CONTACT: 'Contact',
      FAQ: 'FAQ',
      DISCLAIMER: 'Démenti',
      MISC: 'Corona',
      PROCEDURE_STEP: 'Combien de temps devez-vous rester à l’endroit où vous êtes actuellement ? Où irez-vous après? Combien de temps faut-il pour les procédures?',
      NORWEGIAN: 'Norsk',
      UKRAINA: 'Українська',
      ENGLISH: 'English',
      DISCRIMINATION: 'Discrimination',
      RACISM: 'Racisme',
      SOCIAL_GROUPS: 'Groupes sociaux',
      SPECIAL_SITUATIONS: 'Situations spéciales',
      HATE_SPEECH: 'Discours de haine',
  }
};

export default strings;
