const strings = {
    translation: {
      APP_NAME: 'Finn Fram',
      USEFUL_LINKS: 'Корисні посилання',

      SELECT_YOUR_COUNTRY: 'Виберіть',
      CHANGE_COUNTRY_TITLE: 'Змінити країну призначення',
      CHANGE_COUNTRY_DESCRIPTION: 'Змінюючи країну призначення, дані додатка будуть скинуті. Бажаєте продовжити?',
      CONTINUE: 'Продовжити',
      CANCEL: 'Скасувати',
      DASHBOARD: 'Панель',
      USEFUL_LINKS: 'Корисні посилання',
        ASYLUM_PROCEDURE: 'Процедура утримання',
        HOUSING_AND_SOCIAL: 'Житло та соціальні послуги',
        EDUCATION_AND_WORK: 'Освіта та робота',
        HEALTH: 'Здоров\'я',
        FAMILY_IMMIGRATION: 'Сімейне утримання',
        PSYCHOSOCIAL_SUPPORT: 'Психосоціальна підтримка',
        PROCEDURE_STEP: 'Що далі?',
        LOCATIONS: 'Місця',
        NEWS: 'Новини',
        POINT_IT: 'Point it',
        TRANSPORT: 'Транспорт',
        FIRST_AID: 'Перша допомога',
        TRANSLATE: 'Переклад',
        ABOUT: 'Про додаток',
        TRACING: 'Трасування',
        CONTACT: 'Контакти',
        FAQ: 'FAQ',
        DISCLAIMER: 'Відмова від відповідальності',
        MISC: 'Корона',
        PROCEDURE_STEP: 'Яку тривалість ви знаходитесь на місці, де ви зараз? Де ви піде далі? Яка тривалість процедури?',
        DOWNLOAD_APP: 'Завантажте додаток!',
        DOWNLOAD_APP_DESCRIPTION: 'Наш додаток доступний для смартфонів Apple та Android. Завантажте зараз, щоб почати!',
        INTRO_TEXT: 'Finn Fram допоможе вам орієнтуватися в норвезькому суспільстві та знайти інформацію про норвезькі правила, ваші права та де ви можете отримати допомогу.',
        FOOTER_ABOUT: 'Про Finn Fram',
        FOOTER_CONTACT: '',
        FOOTER_DESCRIPTION: 'Finn Fram',
      FEATURES_TITLE: 'Дізнайтеся про інформацію в додатку',
      FEATURES: 'Завантажте додаток, щоб <ul> <li> отримати інформацію про процедуру утримання </li> <li> дізнатися про правила та правила у вашій країні </li> <li> залишатися в курсі останніх змін </li> <li> і багато іншого </li> </ul>',
      NORWEGIAN: 'Норвезька',
      UKRAINA: 'Українська',
      ENGLISH: 'Англійська',
      LANGUAGE: 'Мова',
      CATEGORY: 'Категорія',
      ABOUT: 'Про додаток',
      DISCRIMINATION: 'дискримінація',
      RACISM: 'расизм',
      SOCIAL_GROUPS: 'Соціальні групи',
      SPECIAL_SITUATIONS: 'Особливі ситуації',
      HATE_SPEECH: 'мова ворожнечі',
    }
  };

  export default strings;
        

