import React from 'react';
import urlSlug from 'url-slug';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { sections } from '../config/sections';
import { useTranslation } from 'react-i18next';
import { Image, Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSelectedCountry } from '../features/countrySwitcher/countrySlice';
import './ArticleListView.css';
import * as LanguageDetect from 'languagedetect';

export const ArticleListView = ({ name, articles }) => {
    const { t } = useTranslation();
    const history = useHistory();


    const key = sections.find(s => s.type == name);
    const selectedCountry = useSelector(getSelectedCountry);

    const generateArticle = (article) => {
        if (article.body === undefined) {
            return <></>;
        }

        return (
            <Container className="article-list-row" key={article.id}>

            <div className="article-list-item">

              <Link className="text-link" to={`/article/${urlSlug(selectedCountry.locationId)}/${urlSlug(article.topicIdentifier.toLowerCase())}`}>{article.title}</Link>
            </div>
          </Container>
    );
}
    return (
        <Container>
             <div><h2 className='Article-title'>{t(key.title)}</h2></div>
            {articles.map(generateArticle)}
        </Container>
    )
}
