const strings = {
    translation: {
        APP_NAME: 'Finn Fram',
        USEFUL_LINKS: 'پیوندهای مفید',

        SELECT_YOUR_COUNTRY: 'Select your destination',
        CHANGE_COUNTRY_TITLE: 'Change country destination',
        CHANGE_COUNTRY_DESCRIPTION: 'By changing the country destination the app data will be reset. Would you like to continue?',
        CONTINUE: 'ادامه',
        CANCEL: 'لغو',
        DASHBOARD: 'داشبورد',
        ASYLUM_PROCEDURE: 'پناهندگی',
        HOUSING_AND_SOCIAL: 'کار و رفاه',
        EDUCATION_AND_WORK: 'آموزش و مدرسه',
        HEALTH: 'سلامت',
        FAMILY_IMMIGRATION: 'انوادە الحاق مجدد با',
        PSYCHOSOCIAL_SUPPORT: 'حمایت روانی-اجتماعی',
        PROCEDURE_STEP: 'حقوق من',
        LOCATIONS: 'Locations',
        NEWS: 'اخبار',
        POINT_IT: 'Point it',
        TRANSPORT: 'حمل‌ونقل',
        FIRST_AID: 'کمک‌های اولیه',
        TRANSLATE: 'خدمات ترجمه',
        ABOUT: 'درباره',
        TRACING: 'ردیابی',
        CONTACT: 'تماس',
        FAQ: 'گزارش دادن',
        DISCLAIMER: 'Disclaimer',
        MISC: 'ویروس کرونا',
        DOWNLOAD_APP: 'برنامه را دانلود کنید!',
        DOWNLOAD_APP_DESCRIPTION: 'برنامه ما در هر دو گوشی Apple و Android دردسترس است. برای شروع، همین‌حالا دانلود کنید!',
        INTRO_TEXT: 'Finn Fram به شما کمک می‌کند تا در جامعه نروژ پیمایش کنید و اطلاعاتی درمورد قوانین نروژ، حقوق خود و جاهایی‌که می‌توانید کمک دریافت کنید بیابید.',
        FOOTER_ABOUT: 'درباره Finn Fram',
        FOOTER_CONTACT: '',
        FOOTER_DESCRIPTION: '',
        FEATURES_TITLE: 'Discover the information within the app',
        FEATURES: 'Download the app to<ul><li>get information about the asylum seeker procedure</li><li>learn about the rules and regulations in your country</li><li>stay informed about the latest changes</li><li>and much more</li></ul>',
        BANNER: 'en.mp4',
        NORWEGIAN: 'Norsk (Norwegian)',
        UKRAINA: 'українська (Ukrainsk)',
        ENGLISH: 'Engelsk (English)',
        LANGUAGE: 'زبان',
        CATEGORY: 'موضوع',
        DISCRIMINATION: 'تبعیض',
        RACISM: 'نژادپرستی',
        SOCIAL_GROUPS: 'گروه‌های اجتماعی',
        SPECIAL_SITUATIONS: 'موقعیت‌های خاص',
        HATE_SPEECH: 'سخنان مشوق عداوت و تنفر',
    }
};

export default strings;
