import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import './Intro.css';

export const Intro = () => {
    const { t } = useTranslation();

    return (
        <div className="page-intro background lead introtext">
            <Container>
                <Row>
                    <Col>
                        <p>{t('INTRO_TEXT')}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
