const strings = {
  translation: {
    APP_NAME: 'Finn Fram',
    USEFUL_LINKS: 'روابط مفيدة',

    SELECT_YOUR_COUNTRY: 'Select your destination',
    CHANGE_COUNTRY_TITLE: 'Change country destination',
    CHANGE_COUNTRY_DESCRIPTION: 'By changing the country destination the app data will be reset. Would you like to continue?',
    CONTINUE: 'متابعة',
    CANCEL: 'إلغاء',
    DASHBOARD: 'لوحة التحكم',
    ASYLUM_PROCEDURE: 'اللجوء',
    HOUSING_AND_SOCIAL: 'العمل والرعاية الاجتماعية',
    EDUCATION_AND_WORK: 'التعليم والمدارس',
    HEALTH: 'صحة',
    FAMILY_IMMIGRATION: 'لم شمل األسرة',
    PSYCHOSOCIAL_SUPPORT: 'الدعم النفسي والاجتماعي',
    PROCEDURE_STEP: 'حقوقي',
    LOCATIONS: 'Locations',
    NEWS: 'الأخبار',
    POINT_IT: 'Point it',
    TRANSPORT: 'وسائل النقل',
    FIRST_AID: 'الإسعافات الأولية',
    TRANSLATE: 'خدمات الترجمة الفورية',
    TRACING: 'خدمة البحث عن المفقودين',
    CONTACT: 'اتصل بنا',
    FAQ: 'الإبلاغ',
    DISCLAIMER: 'Disclaimer',
    MISC: 'فيروس كورونا',
    DOWNLOAD_APP: 'نزِّل التطبيق!',
    DOWNLOAD_APP_DESCRIPTION: 'تطبيقنا متوفر لكل من هواتف Apple وAndroid. نزِّل الآن للبدء!',
    INTRO_TEXT: 'يساعدك Finn Fram على استكشاف المجتمع النرويجي والاطلاع على معلومات عن القوانين النرويجية وحقوقك والأماكن التي يمكنها مساعدتك.',
    FOOTER_ABOUT: 'نبذة عن Finn Fram',
    FOOTER_CONTACT: '',
    FOOTER_DESCRIPTION: '',
    FEATURES_TITLE: 'Discover the information within the app',
    FEATURES: "Download the app to<ul><li>get information about the asylum seeker procedure</li><li>learn about the rules and regulations in your country</li><li>stay informed about the latest changes</li><li>and much more</li></ul>",
    BANNER: 'en.mp4',
    NORWEGIAN: 'Norsk (Norwegian)',
    UKRAINA: 'українська (Ukrainsk)',
    ENGLISH: 'Engelsk (English)',
    LANGUAGE: 'اللغة',
    CATEGORY: 'الموضوع',
    ABOUT: 'نبذة',
    DISCRIMINATION: 'التمييز',
    RACISM: 'العنصرية',
    SOCIAL_GROUPS: 'فئات المجتمع',
    SPECIAL_SITUATIONS: 'الحالات الخاصة',
    HATE_SPEECH: 'خطاب الكراهية',
  }
};

export default strings;
