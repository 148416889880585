import React from 'react';
import { useSelector } from 'react-redux';
import { getUsefulLinks } from '../features/articles/articlesSlice';
import { UsefulLink } from './UsefulLink';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const UsefulLinkList = () => {
    const { t } = useTranslation();
    const links = useSelector(getUsefulLinks);

    return (
        <Container>
            <Row>
                <Col>
                  <h1 className="display-4">{t('USEFUL_LINKS')}</h1>
                </Col>
            </Row>
            <Row>
            {links.map(l => (
                <Col className="mb-3 d-flex align-items-stretch">
                    <UsefulLink key={l.id} link={l} />
                </Col>
            ))}
            </Row>
            
        </Container>
    )
}