import { createSlice } from '@reduxjs/toolkit';
import {languageMap} from "../../config/i18n";
import i18n from "../../i18n";

let lang = languageMap[navigator.language];
if (lang === undefined) {
    lang = 'en';
}
i18n.changeLanguage(lang);

export const languageSlice = createSlice({
    name: 'language',
    initialState: {
        language: lang
    },
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
            i18n.changeLanguage(action.payload);
        }
    }
});

export const { setLanguage } = languageSlice.actions;
export const getLanguage = state => { return state.language.language }
export const getSelectedLanguage = state => { return state.language.language }
export default languageSlice.reducer;
