import React, {useEffect, useState} from 'react';
import {getArticles, getArticlesAsync, getCountries, getLanguages} from '../features/articles/articlesSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import urlSlug from 'url-slug';
import { Image, Container } from 'react-bootstrap';
import { UsefulLink } from './UsefulLink';
import { findByLabelText } from '@testing-library/react';
import './Article.css';
import { getSelectedCountry, setCountry } from '../features/countrySwitcher/countrySlice';
import { setLanguage, getSelectedLanguage } from '../features/language/languageSlice';
import { Loading } from './Loading';
import * as LanguageDetect from 'languagedetect';

export const Article = () => {
    const route = useRouteMatch();
    const { country, slug } = route.params;

    const languages = useSelector(getLanguages);
    const selectedLanguage = useSelector(getSelectedLanguage);
    const countries = useSelector(getCountries);
    const selectedCountry = useSelector(getSelectedCountry);
    const isLoading = useSelector(state => state.articles.loading);
    const articles = useSelector(state => state.articles.articles);
    const usefullinks = useSelector(state => state.articles.usefullinks);
    const [languageDirection, setLanguageDirection] = useState('ltr');
    const [article, setArticle] = useState(undefined);

    const dispatch = useDispatch();


    useEffect(() => {
        console.log(country, selectedCountry)

            const c = countries.find(i => urlSlug(i.locationId.toLowerCase()) === country.toLowerCase());

            dispatch(setCountry(c));

    }, [countries, selectedCountry, countries]);

    useEffect(() => {
        console.log(articles);
        const a = articles.find(a => urlSlug(a.topicIdentifier.toLowerCase()) === slug.toLowerCase());
        console.log(a);
        if (a === undefined || a === null) {
            return;
        }

        const articleBdo = languages.find(l => l.abbr === a.languageAbbr)?.bdo;
        if (articleBdo) {
            setLanguageDirection(articleBdo);
        }

        setArticle(a);

    }, [languages, languageDirection, setLanguageDirection, articles, slug]);

    if (isLoading) {
        return <Loading></Loading>
    }

    /*if (article === undefined && article == null) {
        return <Redirect to="/" />
    }*/

    if (!article) {
        return <p>Not found</p>
    }

    const getUsefulLinks = article => {
        if (article.usefullinkIds === undefined) {
            return;
        }

        return article.usefullinkIds.map(id => {
            const link = usefullinks.find(l => l.id === id);

            if (link === undefined) {
                return;
            }

            return <div dir={`${languageDirection}`}  className={`${languageDirection === 'rtl' ? 'usefullink text-right' : 'usefullink'}`}><UsefulLink link={link} /></div>
        });
    }

    return (
        <Container className="article">
            <h1 dir={`${languageDirection === 'rtl' ? 'rtl' : 'ltr'}`} className={`header display-4 ${languageDirection === 'rtl' ? 'text-right' : ''}`}>{article.title}</h1>
            {article.image && <Image src={article.image} />}
            <div className="filter" dir={`${languageDirection === 'rtl' ? 'rtl' : 'ltr'}`} className={`${languageDirection === 'rtl' ? 'text-right' : ''}`} dangerouslySetInnerHTML={{__html: article.body}} />
            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>{getUsefulLinks(article)}</div>
        </Container>

    );
}
