import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getCountries
} from '../articles/articlesSlice';
import { Dropdown, Image, NavDropdown } from 'react-bootstrap';
import './CountrySwitcher.css';
import { getSelectedCountry, setCountry } from './countrySlice';
import { useHistory } from 'react-router-dom';

const colors = {
    white: '#ffffff',
    black: '#000000',
    lightGrey: '#fef1f1',
    pinkishGrey: '#e1e0e0',
    warmGrey: '#737373',
    oceanBlue: '#82d4ef',
    cerulean: '#008ac5',
    darkCerulean: '#005d94',
    silver: '#c8c7cc',
    fireRed: '#fb0007',
}

const styles = {

        width: 30,
        height: 30,
        borderRadius: 15,
        marginLeft: 5,
        borderColor: colors.white,
        backgroundColor: colors.pinkishGrey,
        borderWidth: 2,
        overflow: 'hidden',

};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="custom-toggle"
    >
      {children}
    </a>
  ));

export const CountrySwitcher = () => {
    const history = useHistory();
    const countries = useSelector(getCountries);
    const selectedCountry = useSelector(getSelectedCountry);
    const dispatch = useDispatch();

    const isSelectedCountry = id => id === selectedCountry.country;

    const changeSelectedCountry = c => {
        dispatch(setCountry(c));
        history.push("/sections");
    }

    return (
        <>
            {countries.map(c => (
                <NavDropdown.Item className="dd-links">
                    <a onClick={() => changeSelectedCountry(c)}>{c.title}</a>
                </NavDropdown.Item>
            ))}
        </>

    );
};
