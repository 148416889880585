import './SectionBubble.css';
import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCountries, getSections} from '../features/articles/articlesSlice';
import { Image, Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sections } from '../config/sections';
import './SectionBubble.css';
import {getSelectedCountry, setCountry} from "../features/countrySwitcher/countrySlice";
import urlSlug from "url-slug";

const getSection = (type) => {
  return sections.find(x => x.type === type);
}


export const sectionColors = [
  '#fef1f1',
];


export const SectionsBubble = () => {
  const sections = useSelector(getSections);
  const countries = useSelector(getCountries);
  const selectedCountry = useSelector(getSelectedCountry);
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const params = useParams();

  useEffect(() => {
    dispatch(setCountry(countries.find(l => urlSlug(l.locationId) === params.slug)));
  }, [params, countries]);


  let sections2 = Object.keys(sections).sort().sort(function(x,y){ return x == 'misc' ? -1 : y == 'misc' ? 1 : 0; }).filter(x => {
    var section = getSection(x);
    return section.image !== undefined;
  });

  const multipleRows = sections2.length > 9;

  const getSectionLine = (sec, startAt) => {
    if (startAt === undefined) {
      startAt = 0;
    }
    return (
        <>
        {sec.map((section, i) => {
          var s = getSection(section);


          if (s !== undefined && s.image !== undefined) {
            return (
              <Col className="bubblesections" md={4} xs={4} lg={3} key={section.type}>
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {t(s.title)}
                    </Tooltip>
                  }>
                    <div>
                      <div className="bubble mx-auto">
                        <Link to={`/sections/${params.slug}/${s.type}`}>
                          <Image className="bubble-img" alt={t(s.title)} src={s.image} />
                        </Link>
                      </div>
                      <div className="text-center mt-2">
                        <Link to={`/sections/${params.slug}/${s.type}`} className="section-text">{t(s.title)}</Link>
                      </div>
                    </div>
                </OverlayTrigger>
              </Col>
            );
          }

        })}
        </>

  );}

  return (
    <Container className="bubble-section-container">
      <div className="bubble-section-container-row">{getSectionLine(sections2)}</div>
    </Container>
  )
}
