import { createSlice } from '@reduxjs/toolkit';

export const countrySlice = createSlice({
    name: 'country',
    initialState: {
        country: 'country',
        title: 'title',
        logo: 'logo',
        locationId: 'locationId'
    },
    reducers: {
        setCountry: (state, action) => {
            state.country = action.payload?.topicIdentifier;
            state.logo = action.payload?.logo;
            state.title = action.payload?.title;
            state.locationId = action.payload?.locationId;
        },
    }
});

export const { setCountry } = countrySlice.actions;
export const getCountry = state => state.country.country;
export const getSelectedCountry = state => state.country;
export default countrySlice.reducer;
