import en from './locales/en';
import ar from './locales/ar';
import fr from './locales/fr';
import ti from './locales/ti';
import no from './locales/no';
import ua from './locales/ua';
import ukr from './locales/ukr';
import so from "./locales/so";
import fa from "./locales/fa";
import li from "./locales/li";
import pl from "./locales/pl";
import es from "./locales/es";


export default {
    en: en,
    ar: ar,
    fr: fr,
    ti: ti,
    ua: ua,
    no: no,
    ukr: ukr,
    uk: ukr,
    so: so,
    fa: fa,
    li: li,
    pl: pl,
    es: es,
    lt: li,
};

export const languageMap = {
    'ar': 'ar',
    'ar-AE': 'ar',
    'ar-BH': 'ar',
    'ar-DZ': 'ar',
    'ar-EG': 'ar',
    'ar-IQ': 'ar',
    'ar-JO': 'ar',
    'ar-KW': 'ar',
    'ar-LB': 'ar',
    'ar-LY': 'ar',
    'ar-MA': 'ar',
    'ar-OM': 'ar',
    'ar-QA': 'ar',
    'ar-SA': 'ar',
    'ar-SY': 'ar',
    'ar-TN': 'ar',
    'ar-YE': 'ar',
    'en': 'en',
    'en-AU': 'en',
    'en-BZ': 'en',
    'en-CA': 'en',
    'en-CB': 'en',
    'en-GB': 'en',
    'en-IE': 'en',
    'en-JM': 'en',
    'en-NZ': 'en',
    'en-PH': 'en',
    'en-TT': 'en',
    'en-US': 'en',
    'en-ZA': 'en',
    'en-ZW': 'en',
    'es': 'es',
    'es-AR': 'es',
    'es-BO': 'es',
    'es-CL':  'es',
    'es-CO':  'es',
    'es-CR':  'es',
    'es-DO':  'es',
    'es-EC':  'es',
    'es-ES':  'es',
    'es-GT':  'es',
    'es-HN':  'es',
    'es-MX':  'es',
    'es-NI':  'es',
    'es-PA':  'es',
    'es-PE':  'es',
    'es-PR':  'es',
    'es-PY':  'es',
    'es-SV':  'es',
    'es-UY':  'es',
    'es-VE':  'es',
    'fa': 'fa',
    'fa-IR': 'fa',
    'lt': 'li', // må endres
    'lt-LT': 'li',
    'nb': 'no',
    'nb-NO': 'no',
    'nn-NO': 'no',
    'pl': 'pl', // polsk
    'pl-PL': 'pl',
    'uk': 'ukr',
    'uk-UA': 'ukr',
    'so': 'so',
    'ti': 'ti'
};


export const talandeWebMapping = {
    "en": "en-gb",
    "no": "no-no",
    "ar": "ar",
    "pl": "pl-pl",
    "ukr": "uk",
    "es": "es-es",
};