import React, {useEffect, useState} from 'react';
import { Navbar, NavDropdown, Row, Container, Col, Image, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Header.css';
import { LanguageAndCountryDropdownItems } from '../features/LanguageAndCountryDropdowns';
import {useSelector} from "react-redux";
import {getSelectedLanguage} from "../features/language/languageSlice";
import {getLanguages} from "../features/articles/articlesSlice";

/*global ReachDeck */
/*eslint no-undef: "error"*/
export const Header = () => {
    const { t, i18n } = useTranslation();
    const languages = useSelector(getLanguages);
    const selectedLanguage = useSelector(getSelectedLanguage);
    const [languageBdo, setLanguageBdo] = useState('ltr');
    const [languageName, setLanguageName] = useState('');

    useEffect(() => {
        const language = languages.find(l => l.abbr == selectedLanguage);
        if (language?.bdo !== undefined && language?.bdo !== null) {
            setLanguageBdo(language.bdo);
        }
        if (language?.title !== undefined && language?.title !== null) {
            setLanguageName(language.title)
        }
        console.log(language);
    }, [languages, selectedLanguage, setLanguageName])

    console.log(selectedLanguage)

    return (
        <div className='' style={{ paddingBottom: 0 }}>
            <Navbar variant="light" bg="white">
                <Container>
                    <Link to="/" className="navbar-brand">
                        <Image className='logoheader' src="/assets/images/logo.png" />
                    </Link>
                    <div className='globalization'>
                        <Image src="/assets/ikon_talandewebb.png" className="reachdeck" onClick={() => ReachDeck.panel.toggleBar()}/>
                        <NavDropdown alignRight={languageBdo === 'ltr' ? true : false} title={languageName} className="dropdown-stuff dropdown-menu-left dropdown-toggle basic-nav-dropdown" id="basic-nav-dropdown"  >
                            <LanguageAndCountryDropdownItems />
                        </NavDropdown>
                    </div>
                </Container>
            </Navbar>
        </div>
    )
};
