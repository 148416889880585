import React from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const Loading = ({ children }) => {
    const isLoading = useSelector(state => state.articles.loading);

    if (isLoading) {
        return (
            <Container className="text-center mb-3 mt-3">
                <Spinner animation="border" role="status" />
            </Container>
        );
    }
    return children;
};
