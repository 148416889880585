const strings = {
    translation: {
        APP_NAME: 'Finn Fram',
        USEFUL_LINKS: 'Naudingos nuorodos',
        SELECT_YOUR_COUNTRY: 'Select your destination',
        CHANGE_COUNTRY_TITLE: 'Change country destination',
        CHANGE_COUNTRY_DESCRIPTION: 'By changing the country destination the app data will be reset. Would you like to continue?',
        CONTINUE: 'Tęsti',
        CANCEL: 'Atšaukti',
        DASHBOARD: 'Ataskaitų sritis',
        ASYLUM_PROCEDURE: 'Asylum procedure',
        HOUSING_AND_SOCIAL: 'Darbas ir socialinė gerovė',
        EDUCATION_AND_WORK: 'Švietimas ir mokykla',
        HEALTH: 'Sveikata',
        FAMILY_IMMIGRATION: 'Family reunification',
        PSYCHOSOCIAL_SUPPORT: 'Psichosocialinė parama',
        PROCEDURE_STEP: 'Mano teisės',
        LOCATIONS: 'Locations',
        NEWS: 'Naujienos',
        POINT_IT: 'Point it',
        TRANSPORT: 'Transportas',
        FIRST_AID: 'Pirmoji pagalba',
        TRANSLATE: 'Vertimo žodžiu paslaugos',
        ABOUT: 'Apie',
        TRACING: 'Šeimos ryšių atkūrimas',
        CONTACT: 'Susisiekti',
        FAQ: 'Pranešimų teikimas',
        DISCLAIMER: 'Disclaimer',
        MISC: 'Koronavirusas',
        DOWNLOAD_APP: 'Atsisiųskite programėlę!',
        DOWNLOAD_APP_DESCRIPTION: 'Mūsų programėlė prieinama tiek Apple, tiek Android telefonuose. Atsisiųskite dabar ir pradėkite!',
        INTRO_TEXT: 'Finn Fram jums padeda orientuotis Norvegijos visuomenėje ir rasti informacijos apie Norvegijos įstatymus, savo teises ir kur galite gauti pagalbos.',
        FOOTER_ABOUT: 'Apie Finn Fram',
        FOOTER_CONTACT: '',
        FOOTER_DESCRIPTION: '',
        FEATURES_TITLE: 'Discover the information within the app',
        FEATURES: 'Download the app to<ul><li>get information about the asylum seeker procedure</li><li>learn about the rules and regulations in your country</li><li>stay informed about the latest changes</li><li>and much more</li></ul>',
        BANNER: 'en.mp4',
        NORWEGIAN: 'Norsk (Norwegian)',
        UKRAINA: 'українська (Ukrainsk)',
        ENGLISH: 'Engelsk (English)',
        LANGUAGE: 'kalba',
        CATEGORY: 'Tema',
DISCRIMINATION: 'Diskriminacija',
RACISM: 'Rasizmas',
SOCIAL_GROUPS: 'Socialinės grupės',
SPECIAL_SITUATIONS: 'Specialios situacijos',
HATE_SPEECH: 'Neapykantos kalba',
    }
};

export default strings;
