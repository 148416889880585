import React from 'react';
import { Card, Image, Container, Row, Col } from 'react-bootstrap';

export const UsefulLink = ({ link }) => {
  return (
    <Card style={{ width: '100%' }}>
    <a className="text-secondary" href={link.url}>
      <Card.Body>
        <Card.Title>{link.title}</Card.Title>
        <Card.Text>
          <Container>
          <Row className="justify-content-start">
            <Col md={4} lg={4}>
            <Image className="img-fluid" src={link.image} fluid thumbnail />
            </Col>
            <Col md={8} lg={8}>
            {link.description}  
            </Col>
          </Row>
          </Container>
        </Card.Text>
      </Card.Body>
      </a>
    </Card>
    
  )
}