const images = {
    LOCATION_IDENTIFIERS: {
        "Ny i Norge": 'assets/images/icons/Lokasjon.png',
        "Helse": 'assets/images/icons/Helse.png',
        "Ukrainere i Norge": 'assets/images/icons/Ukraina.png',
        "My rights": 'assets/images/icons/MineRettigheter.png',
    }
}

export const locations = [
    {
        locationId: "Ny i Norge",
        image: images.LOCATION_IDENTIFIERS["Ny i Norge"]
    },
    {
        locationId: "Ukrainere i Norge",
        image: images.LOCATION_IDENTIFIERS["Ukrainere i Norge"]
    },
    {
        locationId: "Helse",
        image: images.LOCATION_IDENTIFIERS.Helse
    },
    {
        locationId: "My rights",
        image: images.LOCATION_IDENTIFIERS["My rights"]
    }
]
