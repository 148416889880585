import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import articleReducer from '../features/articles/articlesSlice';
import languageReducer from '../features/language/languageSlice';
import countryReducer from '../features/countrySwitcher/countrySlice';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
})

export default configureStore({
  reducer: {
    country: countryReducer,
    articles: articleReducer,
    language: languageReducer
  },
  middleware: customizedMiddleware  
});
