const strings = {
    translation: {
        APP_NAME: 'Finn Fram',
        USEFUL_LINKS: 'Lifaaqyada muhiimka ah',

        SELECT_YOUR_COUNTRY: 'Select your destination',
        CHANGE_COUNTRY_TITLE: 'Change country destination',
        CHANGE_COUNTRY_DESCRIPTION: 'By changing the country destination the app data will be reset. Would you like to continue?',
        CONTINUE: 'Sii wad',
        CANCEL: 'Jooji',
        DASHBOARD: 'Goobta Maamulka',
        ASYLUM_PROCEDURE: 'Magangalyada',
        HOUSING_AND_SOCIAL: 'Shaqada iyo daryeelka',
        EDUCATION_AND_WORK: 'Waxbarashadda & dugsiga',
        HEALTH: 'Caafimaadka',
        FAMILY_IMMIGRATION: 'Mideynta qoysaska',
        PSYCHOSOCIAL_SUPPORT: 'Taageerada dhaqanka iyo bulshadda',
        PROCEDURE_STEP: 'Xuquuqdayda',
        LOCATIONS: 'Locations',
        NEWS: 'Wararka',
        POINT_IT: 'Point it',
        TRANSPORT: 'Gaadiidka',
        FIRST_AID: 'Gargaarka Degdega ah',
        TRANSLATE: 'Adeegyada turjumaada',
        ABOUT: 'Ku saabsan',
        TRACING: 'Adeegga baafinta',
        CONTACT: 'Xidhiidhka',
        FAQ: 'Ka warbixinta',
        DISCLAIMER: 'Disclaimer',
        MISC: 'Korona fayraska',
        DOWNLOAD_APP: 'Soo deji aabka!',
        DOWNLOAD_APP_DESCRIPTION: 'Abkayga waxaa lagu heli karaa labbadaba telefoonada Apple iyo Android. Soo deji hadda si aad u bilowdo!',
        INTRO_TEXT: 'Finn Fram waxay kaa caawisaa dhex marka bulshadda Norway iyo helida macluumaadka ku saabsan sharciyada Norway, xuquuqdaada iyo halka aad caawimo ka heli karto.',
        FOOTER_ABOUT: 'Ku saabsan Finn Fram',
        FOOTER_CONTACT: '',
        FOOTER_DESCRIPTION: '',
        FEATURES_TITLE: 'Discover the information within the app',
        FEATURES: 'Download the app to<ul><li>get information about the asylum seeker procedure</li><li>learn about the rules and regulations in your country</li><li>stay informed about the latest changes</li><li>and much more</li></ul>',
        BANNER: 'en.mp4',
        NORWEGIAN: 'Norsk (Norwegian)',
        UKRAINA: 'українська (Ukrainsk)',
        ENGLISH: 'Engelsk (English)',
        LANGUAGE: 'Luuqadda',
        CATEGORY: 'Mowduuca',
        DISCRIMINATION: 'Takoorka',
        RACISM: 'Cunsuriyada',
        SOCIAL_GROUPS: 'Kooxaha bulshada',
        SPECIAL_SITUATIONS: 'Xaaladaha gaarka ah',
        HATE_SPEECH: 'Hadalka nacaybka',

    }
};

export default strings;
