export const listStyle = {
    DEFAULT: 0,
    NUMBERED: 1,
  };

export const sectionType = {
    ASYLUM_PROCEDURE: 'asylum-procedure',
    HOUSING_AND_SOCIAL: 'housing-and-social',
    EDUCATION_AND_WORK: 'education-and-work',
    HEALTH: 'health',
    FAMILY_IMMIGRATION: 'family-immigration',
    PSYCHOSOCIAL_SUPPORT: 'psychosocial-support',
    PROCEDURE_STEP: 'procedure-step',
    NEWS: 'news',
    POINT_IT: 'point-it',
    TRANSPORT: 'transport',
    FIRST_AID: 'first-aid',
    TRANSLATE: 'translate',
    COUNTRY_INFO: 'country-info',
    TRACING: 'tracing',
    FAQ: 'faq',
    DISCLAIMER: 'disclaimer',
    CONTACT: 'contact',
    ABOUT: 'about',
    ONBOARDING: 'onboarding',
    MISC: 'misc',
    DISCRIMINATION: 'discrimination',
    RACISM: 'racism',
    SOCIAL_GROUPS: 'social-groups',
    SPECIAL_SITUATIONS: 'special-situations',
    HATE_SPEECH: 'hate-speech',
};

export const images = {
    LOGO: 'assets/logo.png',
    CLOSE: 'assets/close.png',
    ARROW_SMALL: 'assets/glyphArrowSmall.png',
    TAB_BAR: {
      DASHBOARD: 'assets/tabs/dashboard.png',
      FAQ: 'assets/tabs/faq.png',
      USEFUL_LINKS: 'assets/tabs/usefulLinks.png',
      CONTACT: 'assets/tabs/contact.png',
      ABOUT: 'assets/tabs/about.png',
    },
    SECTION: {
      ASYLUM_PROCEDURE: '/assets/images/icons/Asyl.png',
      HOUSING_AND_SOCIAL: '/assets/images/icons/Bolig.png',
      EDUCATION_AND_WORK: '/assets/images/icons/Utdanning.png',
      HEALTH: '/assets/images/icons/Helse.png',
      FAMILY_IMMIGRATION: '/assets/images/icons/Gjenforening.png',
      PSYCHOSOCIAL_SUPPORT: '/assets/images/icons/Psykhelse.png',
      PROCEDURE_STEP: '/assets/images/icons/Språk3.png',
      LOCATIONS: '/assets/images/icons/Lokasjon.png',
      NEWS: '/assets/images/icons/Nyheter.png',
      POINT_IT: '/assets/images/icons/Helse3.png',
      TRANSPORT: '/assets/images/icons/Transport.png',
      FIRST_AID: '/assets/images/icons/Forstehjelp.png',
      TRANSLATE: '/assets/images/icons/Sprak.png',
      ABOUT: '/assets/images/icons/Språk2.png',
      TRACING: '/assets/images/icons/Oppsporing.png',
      MISC: '/assets/images/icons/Helse3.png',
      DISCRIMINATION: '/assets/images/icons/Diskriminering.png',
      RACISM: '/assets/images/icons/rasisme.png',
      SOCIAL_GROUPS: '/assets/images/icons/sosialegrupper.png',
      SPECIAL_SITUATIONS: '/assets/images/icons/spesiellesituasjoner.png',
      HATE_SPEECH: '/assets/images/icons/hatefulleytringer.png',
    }
  };

export const sections = [
    {
      type: sectionType.ASYLUM_PROCEDURE,
      title: 'ASYLUM_PROCEDURE',
      image: images.SECTION.ASYLUM_PROCEDURE,
      listStyle: listStyle.NUMBERED,
      inDashboard: true,
    },
    {
      type: sectionType.HOUSING_AND_SOCIAL,
      title: 'HOUSING_AND_SOCIAL',
      image: images.SECTION.HOUSING_AND_SOCIAL,
      listStyle: listStyle.NUMBERED,
      inDashboard: true,
    },
    {
      type: sectionType.EDUCATION_AND_WORK,
      title: 'EDUCATION_AND_WORK',
      image: images.SECTION.EDUCATION_AND_WORK,
      listStyle: listStyle.NUMBERED,
      inDashboard: true,
    },
    {
      type: sectionType.HEALTH,
      title: 'HEALTH',
      image: images.SECTION.HEALTH,
      listStyle: listStyle.NUMBERED,
      inDashboard: true,
    },
    {
      type: sectionType.FAMILY_IMMIGRATION,
      title: 'FAMILY_IMMIGRATION',
      image: images.SECTION.FAMILY_IMMIGRATION,
      listStyle: listStyle.NUMBERED,
      inDashboard: true,
    },
    {
      type: sectionType.PSYCHOSOCIAL_SUPPORT,
      title: 'PSYCHOSOCIAL_SUPPORT',
      image: images.SECTION.PSYCHOSOCIAL_SUPPORT,
      listStyle: listStyle.NUMBERED,
      inDashboard: true,
    },
    {
      type: sectionType.PROCEDURE_STEP,
      title: 'PROCEDURE_STEP',
      description: 'SECTION.DESCRIPTION.PROCEDURE_STEP',
      image: images.SECTION.PROCEDURE_STEP,
      listStyle: listStyle.NUMBERED,
      inDashboard: true,
    },
    {
      type: sectionType.NEWS,
      title: 'NEWS',
      image: images.SECTION.NEWS,
      listStyle: listStyle.DEFAULT,
      inDashboard: true,
    },
    {
      type: sectionType.POINT_IT,
      title: 'POINT_IT',
      image: images.SECTION.POINT_IT,
      listStyle: listStyle.DEFAULT,
      inDashboard: true,
    },
    {
      type: sectionType.TRANSPORT,
      title: 'TRANSPORT',
      image: images.SECTION.TRANSPORT,
      listStyle: listStyle.DEFAULT,
      inDashboard: true,
    },
    {
      type: sectionType.FIRST_AID,
      title: 'FIRST_AID',
      image: images.SECTION.FIRST_AID,
      listStyle: listStyle.DEFAULT,
      inDashboard: true,
    },
    {
      type: sectionType.TRANSLATE,
      title: 'TRANSLATE',
      image: images.SECTION.TRANSLATE,
      listStyle: listStyle.DEFAULT,
      inDashboard: true,
    },
    {
      type: sectionType.COUNTRY_INFO,
      title: 'ABOUT',
      image: images.SECTION.ABOUT,
      listStyle: listStyle.DEFAULT,
      inDashboard: true,
    },
    {
      type: sectionType.TRACING,
      title: 'TRACING',
      image: images.SECTION.TRACING,
      listStyle: listStyle.DEFAULT,
      inDashboard: true,
    },
    {
      type: sectionType.FAQ,
      title: 'FAQ',
      listStyle: listStyle.DEFAULT,
      inDashboard: false,
    },
    {
      type: sectionType.CONTACT,
      title: 'CONTACT',
      listStyle: listStyle.DEFAULT,
      inDashboard: false,
    },
    {
      type: sectionType.ABOUT,
      title: 'ABOUT',
      listStyle: listStyle.DEFAULT,
      inDashboard: false,
    },
    {
      type: sectionType.DISCLAIMER,
      title: 'DISCLAIMER',
      listStyle: listStyle.DEFAULT,
      inDashboard: false,
    },
    {
        type: sectionType.ONBOARDING,
        title: 'onboarding',
        listStyle: listStyle.DEFAULT,
        inDashboard: false,
    },
    {
        type: sectionType.MISC,
        title: 'MISC',
        image: images.SECTION.MISC,
        listStyle: listStyle.DEFAULT,
        inDashboard: false,
    },
    {
        type: sectionType.DISCRIMINATION,
        title: 'DISCRIMINATION',
        image: images.SECTION.DISCRIMINATION,
        listStyle: listStyle.DEFAULT,
        inDashboard: false,
    },
    {
        type: sectionType.RACISM,
        title: 'RACISM',
        image: images.SECTION.RACISM,
        listStyle: listStyle.DEFAULT,
        inDashboard: false,
    },
    {
        type: sectionType.SOCIAL_GROUPS,
        title: 'SOCIAL_GROUPS',
        image: images.SECTION.SOCIAL_GROUPS,
        listStyle: listStyle.DEFAULT,
        inDashboard: false,
    },
    {
        type: sectionType.SPECIAL_SITUATIONS,
        title: 'SPECIAL_SITUATIONS',
        image: images.SECTION.SPECIAL_SITUATIONS,
        listStyle: listStyle.DEFAULT,
        inDashboard: false,
    },
    {
        type: sectionType.HATE_SPEECH,
        title: 'HATE_SPEECH',
        image: images.SECTION.HATE_SPEECH,
        listStyle: listStyle.DEFAULT,
        inDashboard: false,
    },
  ];
