import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../App.css';
import './Footer.css';


export const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="footer">
            <Container>
                <Row>
                    <Col>


                        <Link to={`/about`} className={"about"}>🏳 {t('FOOTER_ABOUT')}</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
