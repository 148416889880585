const strings = {
    translation: {
        APP_NAME: 'Finn Fram',
        USEFUL_LINKS: 'Enlaces útiles',

        SELECT_YOUR_COUNTRY: 'Select your destination',
        CHANGE_COUNTRY_TITLE: 'Change country destination',
        CHANGE_COUNTRY_DESCRIPTION: 'By changing the country destination the app data will be reset. Would you like to continue?',
        CONTINUE: 'Continuar',
        CANCEL: 'Cancelar',
        DASHBOARD: 'Panel principal',
        ASYLUM_PROCEDURE: 'Asylum procedure',
        HOUSING_AND_SOCIAL: 'Trabajo y bienestar',
        EDUCATION_AND_WORK: 'Educación y escolarización',
        HEALTH: 'Salud',
        FAMILY_IMMIGRATION: 'Family reunification',
        PSYCHOSOCIAL_SUPPORT: 'Apoyo psicosocial',
        PROCEDURE_STEP: 'Mis derechos',
        LOCATIONS: 'Locations',
        NEWS: 'Noticias',
        POINT_IT: 'Point it',
        TRANSPORT: 'Transporte',
        FIRST_AID: 'Primeros auxilios',
        TRANSLATE: 'Servicios de interpretación',
        TRACING: 'Servicio de búsqueda',
        CONTACT: 'Contacto',
        FAQ: 'Denuncias',
        DISCLAIMER: 'Disclaimer',
        MISC: 'Coronavirus',
        DOWNLOAD_APP: '¡Descargue la app!',
        DOWNLOAD_APP_DESCRIPTION: 'Nuestra app está disponible en teléfonos Apple y Android. ¡Descárgala ahora para empezar!',
        INTRO_TEXT: 'Finn Fram le ayuda a navegar por la sociedad noruega y encontrar información sobre las normas noruegas, sus derechos y dónde puede obtener ayuda.',
        FOOTER_ABOUT: 'Acerca de Finn Fram',
        FOOTER_CONTACT: '',
        FOOTER_DESCRIPTION: '',
        FEATURES_TITLE: 'Discover the information within the app',
        FEATURES: 'Download the app to<ul><li>get information about the asylum seeker procedure</li><li>learn about the rules and regulations in your country</li><li>stay informed about the latest changes</li><li>and much more</li></ul>',
        BANNER: 'en.mp4',
        NORWEGIAN: 'Norsk (Norwegian)',
        UKRAINA: 'українська (Ukrainsk)',
        ENGLISH: 'Engelsk (English)',
        LANGUAGE: 'Idioma',
        CATEGORY: 'Tema',
        ABOUT: 'Acerca de',
        DISCRIMINATION: 'Discriminación',
        RACISM: 'Racismo',
        SOCIAL_GROUPS: 'Grupos sociales',
        SPECIAL_SITUATIONS: 'Situaciones especiales',
        HATE_SPEECH: 'Incitación al odio',
    }
};

export default strings;
