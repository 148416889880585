import React, { useState, useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {CountryDropdownMenu} from "./countrySwitcher/CountryDropdownMenu";
import {LanguageDropdownItem} from "./language/LanguageDropdownMenu";




export const LanguageAndCountryDropdownItems = () => {
    const { t } = useTranslation();

    return (

            <LanguageDropdownItem />

    );
};
