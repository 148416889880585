import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getArticlesAsync, getCountries, getLanguages
} from './features/articles/articlesSlice';
import './App.css';
import { Header } from './app/Header';
import { Article } from './app/Article';
import { getSelectedLanguage } from './features/language/languageSlice';
import { ArticleHandler } from './app/ArticleHandler';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import {getCountry, getSelectedCountry} from './features/countrySwitcher/countrySlice';
import './i18n';
import { UsefulLinkList } from './app/UsefulLinkList';
import { Main } from './app/Main';
import { LanguageSelectorPage } from './app/LanguageSelectorPage';
import { Footer } from './app/Footer';
import { Loading } from './app/Loading';
import { HeaderForLanguageSelectorPage } from './app/HeaderForLanguageSelectorPage';
import { CountrySelectorPage } from './app/CountrySelectorPage';
import { About } from './app/About';
import { talandeWebMapping } from './config/i18n';

function App() {
  const dispatch = useDispatch();

  const selectedLanguage = useSelector(getSelectedLanguage)
  const selectedCountry = useSelector(getCountry);
  const languages = useSelector(getLanguages);
  const [languageDirection, setLanguageDirection] = useState('ltr');
  const [talandeWebLanguage, setTalandeWebLanguage] = useState("en-gb");

  useEffect(() => {
    if (selectedLanguage !== undefined && selectedCountry !== undefined && selectedLanguage !== null && selectedCountry !== null) {
      dispatch(getArticlesAsync(selectedLanguage, selectedCountry));
    }
  }, [selectedLanguage, selectedCountry])

  useEffect(() => {
    setLanguageDirection(languages.find(l => l.abbr === selectedLanguage)?.bdo);
    setTalandeWebLanguage(talandeWebMapping[selectedLanguage] || selectedLanguage);
  }, [languages, languageDirection, setLanguageDirection]);

  return (
    <Router>
      {/* <link href="https://fonts.googleapis.com/css2?family=Hanalei+Fill&display=swap" rel="stylesheet"></link> */}
      <div className="App" dir={languageDirection} lang={talandeWebLanguage}>
        <Switch>
          <Route path="/corona">
            <>
              <Header />
              <Loading>
              <ArticleHandler />
              </Loading>
            </>
          </Route>
          <Route path="/covid-19">
            <>
              <Header />
              <Loading>
              <ArticleHandler />
              </Loading>
            </>
          </Route>
          <Route path="/covid">
            <>
              <Header />
              <Loading>
              <ArticleHandler />
              </Loading>
            </>
          </Route>
          <Route path="/covid19">
            <>
              <Header />
              <Loading>
              <ArticleHandler />
              </Loading>
            </>
          </Route>
          <Route path="/sections/:slug/:name">
            <>
              <Header />
              <Loading>

                <ArticleHandler />
              </Loading>
            </>
          </Route>
          <Route path="/links">
            <>
              <Header />
             <Loading>
              <UsefulLinkList />
             </Loading>
            </>
          </Route>
          <Route path="/about">
            <>
              <Header />
             <Loading>
              <About />
             </Loading>
            </>
          </Route>
          <Route path="/article/:country/:slug">
            <>
              <Header />
              <Loading>
              <Article />
              </Loading>
            </>
          </Route>
          <Route path="/sections/:slug">
            <>
              <Header />
              <Loading>
              <Main />
              </Loading>
            </>
          </Route>
          <Route path="/">
            <Header />
            <CountrySelectorPage />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
