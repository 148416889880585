const strings = {
    translation: {
        APP_NAME: 'Finn Fram',
        USEFUL_LINKS: 'Przydatne linki',

        SELECT_YOUR_COUNTRY: 'Select your destination',
        CHANGE_COUNTRY_TITLE: 'Change country destination',
        CHANGE_COUNTRY_DESCRIPTION: 'By changing the country destination the app data will be reset. Would you like to continue?',
        CONTINUE: 'Kontynuuj',
        CANCEL: 'Anuluj',
        DASHBOARD: 'Pulpit',
        ASYLUM_PROCEDURE: 'Asylum procedure',
        HOUSING_AND_SOCIAL: 'Praca i opieka społeczna',
        EDUCATION_AND_WORK: 'Szkoła i edukacja',
        HEALTH: 'Zdrowie',
        FAMILY_IMMIGRATION: 'Family reunification',
        PSYCHOSOCIAL_SUPPORT: 'Wsparcie psychospołeczne',
        PROCEDURE_STEP: 'Moje prawa',
        LOCATIONS: 'Locations',
        NEWS: 'Wiadomości',
        POINT_IT: 'Point it',
        TRANSPORT: 'Transport',
        FIRST_AID: 'Pierwsza pomoc',
        TRANSLATE: 'Usługi tłumaczeń',
        ABOUT: 'Informacje',
        TRACING: 'Biuro Informacji i Poszukiwań',
        CONTACT: 'Kontakt',
        FAQ: 'Zgłaszanie nieprawidłowości',
        DISCLAIMER: 'Disclaimer',
        MISC: 'Koronawirus',
        DOWNLOAD_APP: 'Pobierz aplikację!',
        DOWNLOAD_APP_DESCRIPTION: 'Aplikacja jest dostępna w wersji na Androida i telefony Apple. Pobierz już teraz, aby rozpocząć!',
        INTRO_TEXT: 'Finn Fram pomaga osobom przybywającym do Norwegii odnaleźć się w społeczeństwie i zapewnia informacje na temat norweskich przepisów, przysługujących praw oraz miejsc, w których można uzyskać pomoc.',
        FOOTER_ABOUT: 'O Finn Fram',
        FOOTER_CONTACT: '',
        FOOTER_DESCRIPTION: '',
        FEATURES_TITLE: 'Discover the information within the app',
        FEATURES: 'Download the app to<ul><li>get information about the asylum seeker procedure</li><li>learn about the rules and regulations in your country</li><li>stay informed about the latest changes</li><li>and much more</li></ul>',
        BANNER: 'en.mp4',
        NORWEGIAN: 'Norsk (Norwegian)',
        UKRAINA: 'українська (Ukrainsk)',
        ENGLISH: 'Engelsk (English)',
        LANGUAGE: 'Język',
        CATEGORY: 'Motyw',
DISCRIMINATION: 'Dyskryminacja',
RACISM: 'Rasizm',
SOCIAL_GROUPS: 'Grupy społeczne',
SPECIAL_SITUATIONS: 'Sytuacje szczególne',
HATE_SPEECH: 'Mowa nienawiści',
    }
};

export default strings;
