import React, {useEffect, useState} from 'react';
import { Article } from './Article';
import { ArticleListView } from './ArticleListView';
import { useRouteMatch, Redirect } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getCountries, getSections} from '../features/articles/articlesSlice';
import urlSlug from 'url-slug';
import {getSelectedCountry, setCountry} from '../features/countrySwitcher/countrySlice';
import {Loading} from "./Loading";

export const ArticleHandler = () => {
    const route = useRouteMatch();
    const countries = useSelector(getCountries);
    const selectedCountry = useSelector(getSelectedCountry);
    const sections = useSelector(getSections);

    const dispatch = useDispatch();

    const isLoading = useSelector(state => state.articles.loading);
    const [section, setSection] = useState(undefined);
    const [isArticleList, setIsArticlesList] = useState(undefined);
    const [displayListView, setDisplayListView] = useState(undefined);
    console.log(route);

    let { name = "misc" } = route.params;
    const { slug } = route.params;



    useEffect(() => {
        const c = countries.find(i => urlSlug(i.locationId.toLowerCase()) === slug.toLowerCase());
        if (c !== undefined || c !== null) {
            console.log("DISPATCHING")
            dispatch(setCountry(c));
        }

        console.log(slug);
        console.log(countries);
    }, [countries, selectedCountry, countries]);

    useEffect(() => {
        if (sections[name] !== undefined && sections[name] !== null) {
            const section = sections[name];
            const hasArticles = section !== undefined && section.length > 0 || false;

            setSection(section);
            setIsArticlesList(hasArticles)
            console.log(section);
            setDisplayListView(section.length > 1 || false);
        }
        console.log(sections);
    }, [sections, setSection, setIsArticlesList])

    if (isLoading) return <Loading></Loading>

    if (displayListView) {
        return <div className='articlelist'><ArticleListView name={name} articles={section} /></div>
    }

    if (section !== undefined) {
        const article = section[0];
        return <Redirect
            to={`/article/${urlSlug(selectedCountry.locationId.toLowerCase())}/${urlSlug(article.topicIdentifier.toLowerCase())}`}/>
    }

    return (<div></div>);
}
