import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next"
import { CountrySwitcher } from '../features/countrySwitcher/CountrySwitcher';
import { Language } from '../features/language/Language';
import { Container, Row, Col, Nav, } from 'react-bootstrap';
import { Download } from './Download';
import { Sections } from './Sections';
import { Loading } from './Loading';
import { Link } from 'react-router-dom';
import { Features } from './Features';
import { useSelector } from 'react-redux';
import { getSelectedLanguage } from '../features/language/languageSlice';
import { getSelectedCountry } from '../features/countrySwitcher/countrySlice';
import { Intro } from './Intro';
import { SectionsBubble } from './SectionsBubble';
import { useParams } from "react-router-dom";
import {getArticlesAsync} from "../features/articles/articlesSlice";

export const Main = (route) => {
    const { t } = useTranslation();

    const selectedLanguage = useSelector(getSelectedLanguage);
    const selectedCountry = useSelector(getSelectedCountry);
    useEffect(() => {
        console.log("YO2", selectedLanguage, selectedCountry)
        //getArticlesAsync(selectedLanguage, selectedCountry));
    }, [selectedLanguage, selectedCountry])
    let banner = t('BANNER');
useEffect(() => {

})

    return (
        <>
            <div className='introbg'>
                <Container>
                    <Row>
                        <Col>
                            <Intro />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='sections'>
                <Container>
                    <Row>
                        <Col>
                            <SectionsBubble />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='downloadbg'>
                <Container>
                    <Row>
                        <Col>
                            <Download />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
