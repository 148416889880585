const strings = {
    translation: {
        APP_NAME: 'Finn Fram',
        USEFUL_LINKS: 'Корисні посилання',

        SELECT_YOUR_COUNTRY: 'Select your destination',
        CHANGE_COUNTRY_TITLE: 'Change country destination',
        CHANGE_COUNTRY_DESCRIPTION: 'By changing the country destination the app data will be reset. Would you like to continue?',
        CONTINUE: 'Продовжити',
        CANCEL: 'Зупинити',
        DASHBOARD: 'Панель приладів',
        ASYLUM_PROCEDURE: 'Подати заяву щодо отримання притулку',
        HOUSING_AND_SOCIAL: 'Робота і соціальне забезпечення',
        EDUCATION_AND_WORK: 'Освіта і школа',
        HEALTH: "здоров'я",
        FAMILY_IMMIGRATION: "Возз'єднання сім'ї",
        PSYCHOSOCIAL_SUPPORT: 'Перша психосоціальна допомога',
        PROCEDURE_STEP: 'Мої права',
        LOCATIONS: 'Locations',
        NEWS: 'Новини',
        POINT_IT: 'Point it',
        TRANSPORT: 'Транспорт',
        FIRST_AID: 'Перша медична допомога',
        TRANSLATE: 'Про службу перекладу',
        ABOUT: 'Про додаток',
        TRACING: 'Відстеження',
        CONTACT: "Зв'яжіться з нами",
        FAQ: 'Інформування',
        DISCLAIMER: 'Disclaimer',
        MISC: 'коронавірус',
        DOWNLOAD_APP: 'Завантажити додаток!',
        DOWNLOAD_APP_DESCRIPTION: 'Програма доступна на телефонах Apple та Android. Завантажте зараз, щоб розпочати!',
        INTRO_TEXT: 'Finn Fram допоже вам зорієнтуватися в норвезькому суспільстві та знайти інформацію щодо норвезьких правил, ваших прав та місць, де ви можете отримати допомогу.',
        FOOTER_ABOUT: 'Про Finn Fram',
        FOOTER_CONTACT: '',
        FOOTER_DESCRIPTION: '',
        FEATURES_TITLE: 'Discover the information within the app',
        FEATURES: 'Download the app to<ul><li>get information about the asylum seeker procedure</li><li>learn about the rules and regulations in your country</li><li>stay informed about the latest changes</li><li>and much more</li></ul>',
        BANNER: 'en.mp4',
        NORWEGIAN: 'Norsk (Norwegian)',
        UKRAINA: 'українська (Ukrainsk)',
        ENGLISH: 'English (Engelsk)',
        LANGUAGE: 'Мова',
        CATEGORY: 'Тема',
        DISCRIMINATION: 'Дискримінація',
        RACISM: 'Расизм',
        SOCIAL_GROUPS: 'Соціальні групи',
        SPECIAL_SITUATIONS: 'Спеціальні ситуації',
        HATE_SPEECH: 'Ненависть',

    }
};

export default strings;
